<template>
    <div class="file-upload-card d-flex align-items-center justify-content-center cursor-pointer" @click="()=>$emit('onCreate')">
        <div class="text-center ">
            <img src="@/assets/images/app-icons/plus_circle.png" alt="" class="mb-12"><br>
            <span class="small-text">{{name}}</span>
        </div>

    </div>
</template>
<script>
export default{
    props:{
        name:[String]
    },
    data(){
        return{
        }
    }
}
</script>
<style scoped>
.file-upload-card{
    border:1px dashed #004BFF;
    border-radius:6px;
    background-color: #ecf2ff;
    vertical-align: middle;
    margin-bottom: 2rem;
    min-height: 170px;
}
.mb-12{
    margin-bottom: 12px;
}
.small-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #4B465C;
}
</style>