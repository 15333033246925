<template>
    <div class="card" @mouseover="active=true" @mouseleave="active=false" :class="[active?'card-shadow':'']" @click="moveNext">
        <img src="@/assets/images/app-icons/folder.png" alt="" width="36px" height="29px">
        <div class="d-flex justify-content-between my-1">
            <div>
                <p class="title" v-b-tooltip.hover.v-primary="name">{{getName}}</p>
                <p class="simple-text">{{ total }} Documents</p>    
            </div>
            <div>
                <!-- <b-avatar-group size="32px">
                    <b-avatar
                      class="pull-up" v-for="(c,index) in collaborators" :key="index"
                      :text="c.email.slice(0,1).toUpperCase()"
                      :variant="colors[index]"
                      v-b-tooltip.hover.v-primary="c.email"
                    />
                </b-avatar-group> -->
                <b-avatar-group size="24px">
                    <b-avatar
                    :class="[collaborators.length<2?'mx-0':'']" v-for="(c,index) in collaborators.slice(0,1)" :key="index"
                    :text="c.email.slice(0,1).toUpperCase()"
                    :variant="colors[index]"
                    v-b-tooltip.hover.v-primary="c.email"
                  />
                  <b-avatar v-if="collaborators.length > 1" 
                    class="mx-0" 
                    :text="`+${collaborators.length-1}`"
                    variant="light-success"
                    id="tooltip-avatar"
                    v-b-tooltip.hover.v-primary="getcollaborators(collaborators.slice(1))"
                    />
                    <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in collaborators.slice(1)" :key="index">{{c.email}}<br></span></b-tooltip> -->
                </b-avatar-group>
            </div>
        </div>
        <div class="d-flex border-top pt-1">
            <div class="mr-1">
                <b-avatar
                    :text="owner.slice(0,1).toUpperCase()"
                    :variant="colors[0]"
                  />
            </div>
            <div>
                <p class="owner-title">{{owner}}</p>
                <p class="text-small">Owner</p>    
            </div>
        </div>
    </div>
</template>
<script>
import {BAvatar, BAvatarGroup,BTooltip,	VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default{
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    components:{
        BAvatar,
        BAvatarGroup,
        BTooltip,
    },
    props:{
        name:[String],
        total:[String, Number],
        owner:[String],
        collaborators:[Array],
    },
    data(){
        return{
            active:false,
            colors:['light-primary','light-info','light-success','light-danger','light-secondary']
        }
    },
    computed:{
        getName(){
            return this.name.length>15? this.name.slice(0,14)+'...':this.name;
        }
    },
    methods:{
        moveNext(){
            this.$emit('moveNext')
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        }
    }
}
</script>
<style scoped>
.card{
    padding: 24px;
    border-radius: 6px;
    background: #ffffff;
    cursor: pointer;
}
.title{
    font-size:16px;
    font-weight:500;
    line-height:24px;
    margin-bottom:0px;
    color:#4B465C;
}
.simple-text{
    font-size:13px;
    font-weight:400;
    line-height:20px;
    margin-bottom:0px;
    color:#4B465C;
}
.owner-title{
    font-size:14px;
    font-weight:500;
    line-height:20px;
    margin-bottom:0px;
    color:#4B465C;
}
.text-small{
    font-size:12px;
    font-weight:400;
    line-height:15px;
    margin-bottom:0px;
    color:#4B465C;
}
.card-shadow{
    box-shadow: 26px 3px 134px -69px rgba(0,75,255,0.51);
    -webkit-box-shadow: 26px 3px 134px -69px rgba(0,75,255,0.51);
    -moz-box-shadow: 26px 3px 134px -69px rgba(0,75,255,0.51);
}
</style>